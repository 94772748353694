import React from "react"
import useEnv from "../hooks/useEnv";
import "../styles/downloadButtons.scss"

const AndroidDownload = require("../images/android-fr.png")
const iOSDownload = require("../images/ios-fr.png")

export default function DownloadButtons()
{
    const ENV = useEnv();

    return <>
        <div className="uevnt-downloads">
            <a href={ENV?.download?.android}><img className="download-img" src={AndroidDownload} alt="Android"/></a>
            <a href={ENV?.download?.ios}><img className="download-img" src={iOSDownload} alt="ios"/></a>
        </div>
    </>
}