import React from "react";
import {Link} from "react-router-dom";

export default function Footer()
{
    const FooterLink = ({to, children}) =>
        <Link to={to} className="mx-1 text-footer-button text-decoration-none">{children}</Link>

    return <div className="uevnt-footer d-flex justify-content-between align-items-center p-2 flex-column-reverse flex-lg-row">
        <p className="mb-0 mt-3 mt-lg-0 text-footer-copyright">
            Copyright © {(new Date().getFullYear())}. All rights reserved. uEvnt Corp
        </p>

        <div className="text-footer-buttons">
            <FooterLink to="/">APPLICATIONS</FooterLink>
            <FooterLink to="/contact">CONTACT</FooterLink>
            <FooterLink to="/privacy">POLITIQUE DE CONFIDENTIALITÉ</FooterLink>
        </div>
    </div>
}