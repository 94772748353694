import React, {useState} from "react"
import Footer from "../components/Footer";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

import "../styles/contact.scss"

export default function Contact()
{
    const DEFAULT = {
        FORM: {
            lastname: "",
            firstname: "",
            email: "",
            subject: "",
            message: "",
            gdprConsent: false
        },
        INVALID: {
            lastname: false,
            firstname: false,
            email: false,
            subject: false,
            message: false,
            gdprConsent: false
        }
    }

    const [form, setForm] = useState(DEFAULT.FORM);
    const [formInvalid, setFormInvalid] = useState(DEFAULT.INVALID);
    const [disabledSendButton, setDisabledSendButton] = useState(false);
    const [formMessage, setFormMessage] = useState({
        type: "",
        message: ""
    });
    const { executeRecaptcha } = useGoogleReCaptcha();

    const isValid = (value, invalidValue, propertyName) => {
        if(value === invalidValue){
            setFormInvalid((prev) => ({
                ...prev,
                [propertyName]: true
            }))
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            return;
        }

        let recaptcha = await executeRecaptcha("sendMail");

        let error = false;

        if(!isValid(form.firstname, "", "firstname")) error = true;
        if(!isValid(form.lastname, "", "lastname")) error = true;
        if(!isValid(form.email, "", "email")) error = true;
        if(!isValid(form.subject, "", "subject")) error = true;
        if(!isValid(form.message, "", "message")) error = true;
        if(!isValid(form.gdprConsent, false, "gdprConsent")) error = true;

        if(error){
            return;
        }

        setDisabledSendButton(true);

        try{
            const response = await fetch("/send.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...form, recaptcha: recaptcha})
            });

            if(response.status === 200){
                setForm(DEFAULT.FORM);
                setFormMessage({
                    type: "success",
                    message: "Nous avons bien pris en compte votre demande, nous vous contacterons très vite !"
                })
            }else{
                setFormMessage({
                    type: "danger",
                    message: "Votre message n'a pas été envoyé. Vérifié que vous avez bien renseigné tous les champs obligatoires."
                })
            }
        } catch (e){
            console.log(e);
        } finally {
            setDisabledSendButton(false);
        }
    }

    const handleChangeForm = (e) => {
        setFormInvalid(DEFAULT.INVALID);
        if(e.target.type === "checkbox"){
            setForm((prev) => ({...prev, [e.target.name]: e.target.checked}));
        }else{
            setForm((prev) => ({...prev, [e.target.name]: e.target.value}));
        }
    }

    return <>
        <Container>
            <div className="d-block d-lg-flex align-items-center min-vh-100">
                <div className="w-100">
                    <div className="p-5 bg-dark bg-opacity-25 my-5 mt-lg-0">
                        <h1>CONTACT</h1>

                        <p className="h5 my-3">
                            Vous pouvez nous contacter via ce formulaire ou par email à l'adresse :{" "}
                            <a className="text-decoration-none text-warning" href="mailto:support@uevnt.com">support@uevnt.com</a><br/>
                            Nous vous répondrons dès que possible !
                        </p>

                        <Form id="contact" method="POST" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="lastname">Nom *</Label>
                                        <Input type="text" id="lastname" name="lastname" value={form.lastname} onChange={handleChangeForm} invalid={formInvalid.lastname} required />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="firstname">Prénom *</Label>
                                        <Input type="text" id="firstname" name="firstname" value={form.firstname} onChange={handleChangeForm} invalid={formInvalid.firstname} required />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="email">Email *</Label>
                                        <Input type="email" id="email" name="email" value={form.email} onChange={handleChangeForm} invalid={formInvalid.email} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="subject">Objet *</Label>
                                <Input type="text" id="subject" name="subject" value={form.subject} onChange={handleChangeForm} invalid={formInvalid.subject} required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="message">Message *</Label>
                                <Input type="textarea" id="message" name="message" value={form.message} onChange={handleChangeForm} rows="5" invalid={formInvalid.message} required />
                            </FormGroup>

                            <div className="d-block d-lg-flex justify-content-between align-items-center">
                                <FormGroup check>
                                    <Input type="checkbox" id="gdprConsent" name="gdprConsent" value={form.gdprConsent} onChange={handleChangeForm} invalid={formInvalid.gdprConsent} required />
                                    <Label check for="gdprConsent">
                                        J'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact.
                                    </Label>
                                </FormGroup>
                                <Button type="submit" color="warning" className="px-5 mt-2 mt-lg-0" disabled={disabledSendButton}>Envoyer</Button>
                            </div>

                            <div>
                                <p className={`small mt-3 mb-0 text-end text-${formMessage.type}`}>
                                    {formMessage.message}
                                </p>
                            </div>

                            <p className="small mt-2 text-end">
                                Pour connaître et exercer vos droits, veuillez consulter notre politique de
                                confidentialité.<br/>
                                * Champs obligatoires
                            </p>
                        </Form>
                    </div>

                    <Footer />
                </div>
            </div>
        </Container>
    </>;
}