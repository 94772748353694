import React from "react"
import {UncontrolledCarousel} from "reactstrap"
import DownloadButtons from "../components/DownloadButtons"
import Footer from "../components/Footer";

import "../styles/index.scss"

const CarouselPhone1 = require("../images/phone_1.webp")
const CarouselPhone2 = require("../images/phone_2.webp")
const CarouselPhone3 = require("../images/phone_3.webp")
const CarouselPhone4 = require("../images/phone_4.webp")
const CarouselPhone5 = require("../images/phone_5.webp")
const CarouselPhone6 = require("../images/phone_6.webp")
const CarouselPhone7 = require("../images/phone_7.webp")

const LogoUEVNT = require("../images/uEVNT.png")

export default function Home()
{
    return <>

        <div>
            <div className="d-flex min-vh-100 w-100 uevnt-home-container">
                <div className="w-50 bg-dark">
                    <div className="d-block d-lg-flex align-items-center h-100 w-100">
                        <div className="px-5 py-5 w-100">
                            <div className="text-center">
                                <img src={LogoUEVNT} alt="uEvnt" className="uevnt-home-logo" />
                            </div>

                            <h1 className="pt-5 pb-3 uevnt-home-title"><i>« Tes évènements à portée de main »</i></h1>

                            <p className="mb-5 uevnt-home-description">
                                Tu peux, participer ou créer des évènements de plusieurs catégories en limitant un nombre de personnes.<br/>
                                Découvre réellement ce qu'il se passe autour de toi.
                            </p>

                            <DownloadButtons />
                        </div>
                    </div>
                </div>
                <div className="w-50">
                    <div className="d-block d-lg-flex align-items-center justify-content-center h-100 w-100">
                        <div>
                            <UncontrolledCarousel className="mx-auto uevnt-home-carousel" autoPlay fade items={[
                                {key: 1, src: CarouselPhone1, altText: "...", caption: ""},
                                {key: 2, src: CarouselPhone2, altText: "...", caption: ""},
                                {key: 3, src: CarouselPhone3, altText: "...", caption: ""},
                                {key: 4, src: CarouselPhone4, altText: "...", caption: ""},
                                {key: 5, src: CarouselPhone5, altText: "...", caption: ""},
                                {key: 6, src: CarouselPhone6, altText: "...", caption: ""},
                                {key: 7, src: CarouselPhone7, altText: "...", caption: ""},
                            ]} interval={4000} indicators={false} controls={false} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    </>;
}