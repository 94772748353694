import {useEffect, useState} from "react";

const useEnv = () => {
    const [env, setEnv] = useState({
        "download": {
            "android": "",
            "ios": ""
        }
    });

    useEffect(() => {
        fetch("/env.json").then((response) => {
            if(response.status === 200){
                response.json().then((data) => {
                    setEnv(data);
                })
            }
        })
    }, [])

    return env;
}

export default useEnv;