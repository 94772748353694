import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Home from "./pages/home"
import Contact from "./pages/contact"
import PrivacyPolicies from "./pages/privacy_policies"

import "bootstrap"
import "./styles/app.scss"

const CAPTCHA_KEY = "6LdR7sEjAAAAAGy7ALELM9eT29p4TC0NnSF1CQ62"

function App() {
    return (
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={CAPTCHA_KEY}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        exact
                        element={<Home />}
                    />

                    <Route
                        path="/contact"
                        exact
                        element={<Contact />}
                    />

                    <Route
                        path="/privacy"
                        exact
                        element={<PrivacyPolicies />}
                    />
                </Routes>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    );
}

export default App;
