import React from "react"
import {Container, Table} from "reactstrap"

import "../styles/privacy.scss"
import Footer from "../components/Footer";

export default function PrivacyPolicies()
{
    return<>
        <Container className="my-5 pb-3" style={{backgroundColor: "#070707"}}>
            <h1 className="text-warning mb-5">POLITIQUE DE CONFIDENTIALITE</h1>

            <p>Nous sommes soucieux de préserver la confidentialité des informations personnelles que vous nous transmettez lors de l’utilisation de uEvnt  et nous attachons une grande importance à la protection de votre vie privée. C’est la raison pour laquelle nous avons rédigé ce document (ci-après la « Politique de Confidentialité » ou les « Règles de Confidentialité »). Il s’applique à tous les Utilisateurs de la Plateforme.</p>
            <p>Nous vous invitons à lire avec attention notre Politique de Confidentialité et à nous contacter pour toute question sur la collecte et le traitement de vos Données Personnelles à l’adresse e-mail suivante : <a href="mailto:support@uevnt.com">support@uevnt.com</a></p>
            <p>Sachez enfin que la Politique de Confidentialité fait partie intégrante des Conditions Générales d’Utilisation et qu’à ce titre les définitions qui y sont établies sont applicables au présent document.</p>
            <p><b>Votre vie privée est importante pour uEvnt, c’est la raison pour laquelle nous insistons sur le fait que uEvnt ne vendra jamais vos données à des tiers, ni ne les traitera d’une manière dont vous ne seriez pas informé.</b></p>

            <h4 className="text-warning">1. Champ d’application</h4>

            <p>La Politique de Confidentialité détaille les Données Personnelles collectées et traitées lors de l’utilisation de notre Application, les raisons du traitement des Données Personnelles des Utilisateurs, la manière dont uEvnt les utilise et les fonctionnalités proposées pour permettre aux Utilisateurs d’accéder et de mettre à jour leurs Données Personnelles.</p>
            <p>Une Donnée Personnelle se définit comme toute information se rapportant à une personne physique qui peut être identifiée, directement ou indirectement. Ce sont par exemple : le nom, prénom, adresse email et postale d’une personne physique, son image sur une photographie ou une vidéo, une adresse IP, une donnée de localisation, etc.</p>

            <h4 className="text-warning">2. Acceptation et mise à jour de nos Règles de Confidentialité</h4>

            <p>En acceptant les CGU, vous confirmez avoir pris connaissance et accepter sans restriction ni réserve la présente Politique de Confidentialité qui en font intégralement partie. En cas de désaccord avec l’une ou plusieurs de ses conditions, l’Utilisateur est libre de ne pas ou ne plus utiliser nos services (notamment en cas de modification des termes de la Politique de Confidentialité).</p>
            <p>Les présentes Règles de Confidentialité peuvent être amenées à changer, étant précisé que toute diminution de vos droits ne saurait être appliquée sans votre consentement. uEvnt publiera les nouvelles versions de la Politique de Confidentialité sur l’Application et à l’adresse suivante <a href="https://uevnt.com/privacy">https://uevnt.com/privacy</a> et alertera les Utilisateurs au moyen d’une fenêtre pop-up qui apparaîtra à l’ouverture de l’Application.</p>
            <p>D’une manière générale, la Politique de Confidentialité est toujours facilement accessible à l’adresse <a href="https://uevnt.com/privacy">https://uevnt.com/privacy</a> ou depuis les paramètres de votre Compte Utilisateur.</p>

            <h4 className="text-warning">3. Responsable de traitement des Données Personnelles</h4>

            <p>Le traitement de vos Données Personnelles est soumis à la loi française, notamment à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, modifiée (dite Loi Informatique et Libertés) ainsi qu’au Règlement UE 2016/679 du 27 avril 2016, dit Règlement Général sur la Protection des Données (RGPD).</p>
            <p>Selon la réglementation en vigueur, uEvnt est le Responsable du traitement de vos Données Personnelles.</p>
            <p>En cette qualité, uEvnt s’engage à respecter les dispositions légales en vigueur et à effectuer un traitement licite, loyal et transparent des Données Personnelles des Utilisateurs. uEvnt garantit par ailleurs aux Utilisateurs que ce traitement s’inscrit dans le cadre des finalités explicites et légitimes déterminées dans la Politique de Confidentialité. La durée de traitement n’excède pas la durée nécessaire aux finalités pour lesquelles les Données Personnelles sont collectées et traitées.</p>

            <h4 className="text-warning">4. Catégories de Données Personnelles collectées et traitées lors de l’utilisation de l’Application</h4>

            <p><b>a) Quelles sont les Données Personnelles collectées et comment sont-elles traitées ?</b></p>
            <p>Afin de fournir un accès à la Plateforme à ses Services, uEvnt est susceptible de collecter plusieurs catégories de Données Personnelles vous concernant, pour des finalités, des bases légales de traitement et des durées de conservation différentes. Nous vous proposons une synthèse de l’ensemble de ces informations dans le tableau ci-dessous.</p>

            <ul>
                <li>Si vous souhaitez en savoir un peu plus sur les Données collectées et les finalités de traitement vous pouvez également lire les précisions en-dessous du tableau.</li>
            </ul>

            <Table bordered responsive dark className="bg-dark">
                <thead>
                <tr>
                    <th>Finalité de traitement</th>
                    <th>Données collectées</th>
                    <th>Base légale</th>
                    <th>Durées de conservation</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Création de compte et Identification de l’Utilisateur – Gestion du compte</td>
                    <td>Données de profil obligatoires (Nom ou Prénom, date de naissance, numéro de téléphone, mot de passe)</td>
                    <td>Exécution contractuelle</td>
                    <td>Durée de l’utilisation des services augmentée de 3 ans après la dernière connexion au service ou jusqu’à la demande suppression</td>
                </tr>
                <tr>
                    <td>Création de compte et Identification de l’Utilisateur – Gestion du compte</td>
                    <td>Données de profil optionnelles (biographie, adresse email)</td>
                    <td>Consentement</td>
                    <td>Durée de l’utilisation des services augmentée de 3 ans après la dernière connexion au service ou jusqu’au retrait du consentement</td>
                </tr>
                <tr>
                    <td>Achat de fonctionnalités payantes</td>
                    <td>Données de transaction transmises par le Store</td>
                    <td>Exécution contractuelle</td>
                    <td>Durée nécessaire à l’exécution de la transaction augmentée de la durée légale de conservation à des fins comptables</td>
                </tr>
                <tr>
                    <td>Bon fonctionnement de la Plateforme et de ses Services</td>
                    <td>Données envoyées par l’appareil photo de votre téléphone et photos stockées sur votre téléphone, Données de géolocalisation</td>
                    <td>Consentement</td>
                    <td>Durée de l’utilisation des services augmentée de 3 ans après la dernière connexion ou jusqu’au retrait du consentement</td>
                </tr>
                <tr>
                    <td>Gestion administrative de la Plateforme, des réclamations et signalements des Utilisateurs, Modération de contenu conformément à nos CGU</td>
                    <td>Données relatives au contenu, Communications avec uEvnt</td>
                    <td>Intérêt légitime</td>
                    <td>Durée de l’utilisation des services + prescription légale (5 ans)</td>
                </tr>
                <tr>
                    <td>Modération de contenu conformément à la loi en vigueur (prévention d’activités illégales, fraudes, cyberharcèlement, …)</td>
                    <td>Données relatives au contenu, Données relatives à votre activité sur uEvnt</td>
                    <td>Respect de nos obligations légales</td>
                    <td>1 an à compter de la collecte pour les métadonnées<br/>5 ans (prescription légale) pour les données conservées aux fins de prévention des litiges</td>
                </tr>
                <tr>
                    <td>Mise en relation avec d’autres Utilisateurs</td>
                    <td>Géolocalisation</td>
                    <td>Consentement</td>
                    <td>Durée de l’utilisation des services ou jusqu’au retrait du consentement</td>
                </tr>
                </tbody>
            </Table>

            <p className="ps-3"><b>Précisions sur les Données Personnelle collectées</b></p>
            <p>uEvnt collecte deux types de Données différentes :</p>

            <h6 className="ps-3">1.	(i) Les Données que vous nous transmettez</h6>

            <p><b>Données de profil</b> : Ce sont les Données que vous devez obligatoirement renseigner à l’ouverture de votre compte : votre numéro de téléphone, nom ou prénom, date de naissance, nom d’utilisateur, et mot de passe.</p>
            <p>Vous pouvez également renseigner des Données optionnelles : votre biographie, adresse email, photo de profil.</p>
            <p><b>Communications avec uEvnt</b> : lorsque vous nous signalez un problème sur la plateforme (contenus inappropriés, bugs, erreurs), répondez à un sondage sur la qualité de nos Services, demandez de l’assistance ou faite une demande d’exercice de vos droits nous enregistrons les informations que vous acceptez de nous communiquer ou celles dont nous avons besoin pour répondre à votre demande.</p>

            <h6 className="ps-3">2.	(ii) Les Données que nous collectons lorsque vous utilisez nos Services</h6>

            <p><b>Données relatives à votre activité sur uEvnt</b> : ce sont notamment :</p>
            <ul>
                <li>les Données de connexion : adresse IP, date d’inscription, date de votre dernière connexion ;</li>
            </ul>
            <p><b>Données relatives au contenu</b> : nous recueillons les contenus que vous créez grâce à nos Services comme les photos et les commentaires. Notez que les Utilisateurs qui consultent votre Contenu peuvent toujours en faire une copie ou une capture d’écran. Aussi, nous vous recommandons vivement de ne pas publier ou envoyer à un ami un Contenu que vous ne souhaitez pas voir partagé.</p>
            <p><b>Données de géolocalisation</b> : pour chaque publication d’évènement, la Plateforme vous offre la possibilité de partager votre localisation. Nous vous proposons également de localiser précisément des évènements sur une carte par rapport à votre position. L’utilisation de ses Service nécessite votre consentement à la collecte de Données de géolocalisation.</p>
            <p>Si vous ne souhaitez pas que uEvnt collecte vos Données de géolocalisation vous pourrez refuser de donner l’autorisation à la Plateforme d’accéder à votre localisation lors de la première ouverture de l’application. Si vous souhaitez retirer votre consentement après avoir accepté un tel traitement il vous faut modifier les autorisations Données à l’application dans les paramètres systèmes de votre téléphone</p>
            <p><b>Données relatives aux appareils et équipements utilisés</b> : nous collectons des informations sur et émises par les appareils et équipements que vous utilisez pour accéder à uEvnt. Il s’agit notamment de votre adresse IP, du type d’appareil, des pannes de l’application, de la version du système d’exploitation, de la langue utilisée.</p>
            <p><b>Appareils photo et photos</b> : la plupart de nos Services nécessitent que vous nous autorisiez à collecter des Données provenant de l’appareil photo et de la bibliothèque photos de votre téléphone (envoie et téléchargement de photos). Si vous refusez de nous permettre d’accéder à votre appareil photo ou à votre bibliothèque photos, vous pourrez tout de même créer des publications d’évènements.</p>
            <ul>
                <li><b>Précisions sur les bases légales et les finalités de traitement</b></li>
            </ul>

            <p>Nous traitons les Données ci-dessus décrites conformément aux exigences du RGPD pour différentes finalités sur les bases de :</p>

            <ul>
                <li><b>L’exécution contractuelle</b> lorsque le traitement permet à uEvnt de fournir les Services tels que décrits dans les Conditions Générales d’Utilisation. Cela inclut :</li>
                <ul>
                    <li>L’accès à la Plateforme et aux services connexes (visibilité du profil, transmission des commentaires et contenus partagés) ;</li>
                    <li>Le bon fonctionnement des comptes Utilisateurs  ;</li>
                    <li>Les notifications vous indiquant que l’évènement va bientôt commencer ;</li>
                    <li>La notification des changements apportées à nos services ;</li>
                    <li>Les services d’assistance Utilisateurs ;</li>
                    <li>L’administration de la Plateforme, réparation et correction de bugs, mise à jour ;</li>
                    <li>La fourniture des services de partage de Contenus Utilisateurs et d’interaction avec d’autres Utilisateurs ;</li>
                </ul>
            </ul>

            <ul>
                <li><b>Nos intérêts légitimes</b> lorsque le traitement nous permet de vous proposer une Plateforme efficace et dynamique. Cela inclut :</li>
                <ul>
                    <li>Le contrôle du contenus Utilisateurs, des commentaires, messages et métadonnées associées à des fins administratives et de vérification leur conformité à nos Conditions Générales d’Utilisation ;</li>
                    <li>La vérification de la compatibilité des modalités de présentation du contenu au regard du type d’appareil utilisé ;</li>
                    <li>La compréhension des modalités d’utilisation de la Plateforme par les Utilisateurs à des fins d’amélioration, de développement et de promotion commerciale de l’Application ;</li>
                    <li>La vérification de la stabilité et de la sécurité de la Plateforme ;</li>
                    <li>L’identification d’activités criminelles, de comportement frauduleux, d’utilisation abusive de la Plateforme ;</li>
                </ul>
            </ul>

            <ul>
                <li><b>Nos obligations légales</b> lorsque le traitement de Données vise à prévenir et lutter contre les activités abusives, frauduleuses, et illégales.</li>
                <li><b>Votre consentement</b> lorsque le traitement porte sur des Données de géolocalisation. Cela inclus :</li>
                <ul>
                    <li>La géolocalisation afin de permettre à l’Utilisateur de voir des évènements qui sont géographiquement proche de sa position ;</li>
                    <li>La géolocalisation aux fins d’indiquer où le contenu Utilisateur a été créé ;</li>
                </ul>
            </ul>

            <p><b>a) Cookies</b></p>

            <p>Un « cookie » (ou « traceur ») est un témoin de connexion qui désigne un fichier texte susceptible d’être enregistré dans un espace dédié du disque dur de votre terminal (ordinateur, tablette, ou smartphone), à l’occasion de la consultation d’un service accessible en ligne. Un cookie permet à son émetteur d’identifier le terminal dans lequel il est enregistré, pendant la durée de validité ou d’enregistrement du cookie. Le cookie ne permet donc pas d’identifier l’Utilisateur en tant que tel ; il sert à enregistrer vos informations de navigation sur la Plateforme.</p>

            <p>Lorsque vous vous connectez sur la Plateforme, des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des fichiers « Cookies » installés sur votre terminal, selon les choix que vous avez pu exprimer concernant les cookies et qui reste modifiable à tout moment. Ces cookies permettent une navigation personnalisée et sont également utilisés à des fins analytiques (mesures d’audience).</p>
            <p>L’existence des cookies et leur(s) finalité(s) vous seront indiquées dès votre connexion à la Plateforme, par la présence d’un bandeau d’information placé en bas ou en haut de la page d’accueil.</p>
            <p>Le dépôt et la lecture des cookies sur votre terminal nécessitent en général votre consentement préalable, auquel cas il est recueilli par un clic sur « J’accepte ». L’accord donné pour l’utilisation de cookies a une durée de validité de 13 mois. Passé ce délai, nous vous demanderons de renouveler votre accord.</p>
            <p>En cas de refus des cookies, d’opposition à ceux qui sont déposés par défaut ou de suppression de ceux qui sont enregistrés, l’Utilisateur est informé qu’il ne pourra plus bénéficier d’un certain nombre de fonctionnalités qui sont néanmoins nécessaires pour naviguer dans certains espaces de la Plateforme. Le cas échéant, uEvnt décline toute responsabilité pour les conséquences liées au fonctionnement dégradé de ses Prestations résultant de l’impossibilité pour uEvnt d’enregistrer ou de consulter les cookies nécessaires au bon fonctionnement de la Plateforme et que vous auriez refusés ou supprimés.</p>
            <p>Enfin, il convient de distinguer les cookies émis sur la Plateforme de ceux émis par des tiers. A ce titre, nous vous informons que des cookies peuvent être placés de temps à autre sur certaines pages de la Plateforme par des tiers (annonceurs publicitaires ou autres). Nous vous informons par ailleurs que uEvnt n’exerce aucun contrôle sur l’utilisation de cookies par les tiers.</p>
            <p>uEvnt recourt aux cookies et traceurs suivants :</p>

            <ul>
                <li>Sur l’Application :</li>
                <ul>
                    <li>les cookies nécessaires à l’utilisation des Services, par exemple en vous permettant de rester connecté à votre compte ;</li>
                    <li>les cookies analysant votre utilisation de l’Application afin de générer des rapports statistiques, sans vous identifier :</li>
                    <ul>
                        <li>Google Analytics</li>
                    </ul>
                    <li>les cookies nécessaires au fonctionnement correct et efficace de l’Application, par exemple en sauvegardant vos préférences d’utilisation.</li>
                </ul>
                <li>Sur le site internet <b><i>www.uevnt.com</i></b> :</li>
                <ul>
                    <li>les cookies analysant votre utilisation du site internet afin de générer des rapports statistiques, sans vous identifier :</li>
                    <ul>
                        <li>Google Analytics</li>
                    </ul>
                </ul>
            </ul>

            <h4 className="text-warning">5. Conditions d’utilisation des Données Personnelles</h4>

            <p>Toute utilisation de Données Personnelles dans un but autre que ceux exposés ci-dessus dans nos Règles de Confidentialité nécessitera l’accord préalable exprès de l’Utilisateur.</p>
            <p>uEvnt s’engage à conserver les Données Personnelles des Utilisateurs uniquement pendant la durée strictement nécessaire au(x) traitement(s) déclaré(s) selon les finalités précitées ou à l’exercice et à la constatation de ses droits en justice, et en toute hypothèse dans les limites imposées par la loi.</p>
            <p>uEvnt s’engage à effacer les Données Personnelles de ses bases de données à l’issue de ces différentes durées. En cas de demande de suppression de votre compte, celle-ci sera effective sous 30 (trente) jours. Toutes nos sauvegardes sont automatiquement supprimées après 90 (quatre-vingt-dix) jours.</p>
            <p>Toutefois, uEvnt peut conserver certaines informations pour une période postérieure à la clôture du compte personnel de l’Utilisateur afin de satisfaire à des obligations légales, comptables et fiscales et notamment, afin de prévenir d’éventuels comportements illicites après la suppression d’un Compte Utilisateur (réutilisation d’un ancien compte par un tiers). Dans cette hypothèse, les Données Personnelles seront désactivées et ne seront plus accessibles en ligne.</p>

            <h4 className="text-warning">6. Partage d’informations avec des tiers</h4>

            <p><b>a) Absence de communication des Données Personnelles à des tiers</b></p>
            <p>A l’exception des Contenus sur lesquels vous nous avez concédé une licence d’utilisation conformément aux dispositions des Conditions générales d’utilisation, uEvnt s’engage à ne pas divulguer à des tiers les informations et Données Personnelles communiquées par les Utilisateurs. Ces informations et Données Personnelles sont exclusivement utilisées par les services internes de uEvnt et ne seront en aucun cas cédées, vendues à des tiers sans votre accord préalable exprès.</p>
            <p><b>b) Communication des Données Personnelles pour des besoins de traitement externe</b></p>
            <p>Vos informations et Données Personnelles peuvent, le cas échéant, être transmises à des tiers sous-traitants intervenant dans la fourniture des Services (prestataires technique et d’hébergement, envoie de notifications, publication et partage des contenus, suivi Utilisateur et enquêtes de satisfaction, gestion d’incident de sécurité ou activité frauduleuse, etc.).</p>
            <p>uEvnt s’engage à ne communiquer les Données Personnelles des Utilisateurs qu’à des prestataires habilités et de confiance, qui les traitent pour notre compte, selon nos instructions, conformément aux présentes Règles de Confidentialité et dans le respect de toute autre mesure appropriée de sécurité et de confidentialité.</p>
            <p><b>c) Communication des Données Personnelles en dehors de l’Union Européenne</b></p>
            <p>Le traitement des Données Personnelles dans les conditions décrites dans les Règles de Confidentialité implique parfois de transférer vos Données dans un ou plusieurs autres pays, à l’intérieur ou à l’extérieur de l’Espace Economique Européen, et notamment aux Etats-Unis.</p>
            <p>Le cas échéant nous utilisons les clauses contractuelles type et/ou tout autre mécanismes approuvés par la Commission Européenne pour conserver un niveau de protection de vos Données Personnelles équivalent à celui garanti par le RGPD.</p>
            <p><b>d) Communication pour des raisons d’ordre juridique et légale</b></p>
            <p>Les informations et Données Personnelles pourront également être divulguées à un tiers si uEvnt y est contraint par la loi, une disposition réglementaire, ou une ordonnance judiciaire, ou encore si cette divulgation est rendue nécessaire pour les besoins d’une enquête, injonction ou d’une procédure judiciaire, sur le territoire national ou à l’étranger.</p>
            <p>De même, uEvnt peut être amené à partager les informations et Données Personnelles avec des entreprises, des conseils ou des personnes tierces afin de :</p>
            <ul>
                <li>Faire appliquer la Politique de Confidentialité et les Conditions générales d’utilisation de l’Application en vigueur, y compris pour constater d’éventuels manquements à celles-ci ;</li>
                <li>Se prémunir contre toute atteinte aux droits, aux biens ou à la sécurité de ses utilisateurs, en application et dans le respect de la loi.</li>
            </ul>

            <h4 className="text-warning">7. Sécurité des Données Personnelles</h4>

            <p>Nous mettons en œuvre des mesures techniques et organisationnelles, comme le chiffrement des Données ou la restriction de leur accès au personnel autorisé, pour nous assurer que vos Données Personnelles sont traitées de manière sécurisée dans le respect des Règles de Confidentialité. Cependant, la transmission d’informations via Internet comporte des risques irréductibles qui ne permettent pas de garantir une parfaite sécurité des informations transmises via la Plateforme. En conséquence, toute transmission de Données Personnelles est réalisée à vos propres risques.</p>

            <h4 className="text-warning">8. Respect des droits des Utilisateurs de la Plateforme</h4>

            <p>Vous disposez de certains droits sur vos Données Personnelles dont voici la liste :</p>
            <ol>
                <li>Droit d’accès : il vous permet d’accéder gratuitement aux Données Personnelles collectées à votre égard.</li>
                <li>Droit de rectification :  il vous permet de mettre à jour ou de corriger l’exactitude de vos Données. Vous pouvez exercer ce droit directement depuis les paramètres de votre compte.</li>
                <li>Droit de suppression : il peut être exercé lorsque le traitement des Données Personnelles n’est plus nécessaire au regard des finalités pour lesquelles elles ont été collectées ou, le cas échéant, lorsque vous retirerez votre consentement au traitement.</li>
                <li>Droit d’opposition à la collecte et au traitement de tout ou partie de vos Données Personnelles : ce droit n’est pas absolu et ne s’applique qu’aux Données Personnelles collectées à des fins de prospection commerciale, y compris de profilage lorsqu’il est lié à des activités de prospection commerciale. Lorsque vous choisissez de vous opposer à un tel traitement nous vous demandons de nous indiquer les raisons de votre opposition afin de nous permettre de mettre en balance nos intérêts légitimes avec votre demande.</li>
                <li>Droit de limitation du traitement des Données Personnelles. Ce droit n’est pas absolu, et ne peut s’appliquer que lorsque :</li>
                <ul>
                    <li>une demande de rectification a été effectuée en vertu du droit de rectification décrit au 8.2. Vous pouvez alors demander l’arrêt du traitement de vos Données Personnelles pendant la durée nécessaire à la vérification de l'exactitude des rectifications demandées.</li>
                    <li>vous souhaitez vous opposez à l’effacement de vos Données Personnelles dont le traitement était illicite ;</li>
                    <li>uEvnt n’a plus besoin des Données Personnelles conformément aux finalités de traitement mais celles-ci vous sont encore nécessaires pour la constatation, l’exercice ou la défense de droits en justice ;</li>
                    <li>Vous souhaitez faire usage de votre droit d'opposition décrit au 8.4. Vous pouvez alors demander l'arrêt du traitement de vos Données Personnelles pendant la durée nécessaire à la mise en balance de nos intérêts légitimes avec votre demande.</li>
                </ul>
            </ol>

            <p>Avant de pouvoir répondre à une demande d’exercice d’un ou plusieurs de vos droits, nous pourrions vous demandez de nous confirmer quelques informations de votre Compte afin de vérifier votre identité. En cas de doute raisonnable, nous pourrons vous demander une pièce d’identité.</p>

            <h4 className="text-warning">9. Informations concernant les enfants</h4>

            <p>uEvnt n’est pas destiné aux mineurs de moins de 13 ans. Si vous pensez que nous avons des Données Personnelles concernant un enfant ou qui ont été collectées auprès d’un enfant n’ayant pas l’âge requis, vous pouvez nous faire part de vos doutes en signalant le contenu ou le Compte Utilisateur.</p>

            <h4 className="text-warning">10. Informations concernant les utilisateurs Américains</h4>

            <p>Cette clause n'est valide et juridiquement contraignante que si vous êtes partie aux Conditions Générales d’Utilisation de uEvnt. Cette clause relative aux utilisateurs américains, notamment aux résidents Californiens et de Virginie, s'applique aux renseignements personnels (tels que définis dans les réglementations applicables) fournis à uEvnt par l’Utilisateur.</p>
            <p>À la lumière de la loi californienne sur la protection de la vie privée des consommateurs de 2018 (“CCPA”), nous vous fournissons cette information en tant que fournisseur de services amené à traiter vos informations personnelles (telles que définies dans la loi applicable), afin de confirmer que nous avons mis en place des conditions contractuelles adéquates. Le terme « loi applicable » désigne l'ensemble des lois, règlements, normes, orientations réglementaires et directives d'autoréglementation qui peuvent s'appliquer concernant la gestion de vos Données. Les lois applicables comprennent, sans s'y limiter, la loi californienne sur la protection de la vie privée des consommateurs (Cal. Civ. Code §1798.100 - 1798.199), ou la loi de Virginie sur la protection des données des consommateurs (SB 1392).</p>
            <p>En tant que fournisseur de services, nous collecterons, conserverons, utiliserons, divulguerons et traiterons vos informations personnelles uniquement pour remplir nos obligations envers vous en vertu de notre accord, pour des finalités opérationnelles préalablement mentionnées dans cette Politique, à l’exclusion de toute autre finalité.</p>
            <p>Nous ne vendrons pas vos informations personnelles ou ne divulguerons pas vos informations personnelles à des fins commerciales.</p>
            <p>Nous certifions que nous comprenons et respectons les restrictions et les obligations énoncées dans la loi Cal. Civ. Code § 1798.140(w)(2) ainsi que la loi Va. SB 1392. En cas d'incohérence ou de conflit entre les termes et conditions de la présente clause et ceux des CGU ou de la Politique de confidentialité, ces dernières feront foi en ce qui concerne les Données des personnes dans l'Espace économique européen.</p>
            <p>Pour toute question sur le CCPA ou toute autre réglementation américaine chez uEvnt ou pour exercer vos droits à ce titre, vous pouvez nous contacter tel qu’indiqué à l’article 11.</p>

            <h4 className="text-warning">11. Contact</h4>

            <p>Vous pouvez exercer ces droits à tout moment selon deux modalités au choix :</p>
            <ul>
                <li>Par e-mail à : <a href="mailto:support@uevnt.com">support@uevnt.com</a></li>
            </ul>
            <p>uEvnt s’engage à répondre à vos demandes concernant les Règles de Confidentialité ou l’exercice de leurs droits, dans les meilleurs délais.</p>
        </Container>
        <Container>
            <Footer/>
        </Container>
    </>;
}